export const textStyles = {
    letterSpacing: '0.43px !important',
    fontSize: '14px !important',
    color: '#333333 !important',
    fontWeight: 500,
    fontFamily: 'Roboto',
    width: 0,
    minWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap' as const,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical' as const,
    WebkitLineClamp: '1',
    whiteSpace: 'pre-wrap',
};

export const placeholderTextStyles = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16.41px',
    fontWeight: 300,
    width: '100%',
    margin: 0,
};
